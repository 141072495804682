import axios from 'axios'
import type { AxiosError, InternalAxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios'
import { useToast } from 'vuestic-ui'

const { init } = useToast()

// let ENV = 'https://partyapi.sindraxservices.com/'
let ENV = 'http://192.168.1.163:8087/'

// let PRO = 'https://test.sindraxservices.com:8187/'
let PRO = ' https://partyapi.sindraxservices.com/'
/* 初始化 */
const service: AxiosInstance = axios.create({
  baseURL: import.meta.env.DEV ? ENV : PRO, //后面做跨域请求映射
  timeout: 60000, //超时访问时间

  headers: {
    // 由于我们传的对象都是通过json的格式封装在请求体中，所以需要定义content-type
    'content-type': 'application/json; charset=utf-8',
  },
})

/* 请求拦截器 */
service.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    let token = localStorage.getItem('token')
    if (token) {
      config.headers['Authorization'] = token
    }
    return config
  },
  (error: AxiosError) => {
    // Message.error(error.message);
    init({ message: error.message, color: 'danger' })
    return Promise.reject(error)
  },
)

/* 响应拦截器 */
service.interceptors.response.use(
  (response: AxiosResponse) => {
    const { code, message, data } = response.data

    // 根据自定义错误码判断请求是否成功
    if (code === 200) {
      // 将组件用的数据返回
      return response.data
    } else {
      if (code == 3001) {
        init({ message: 'Incorrect username or password', color: 'danger' })
      } else if (code == 3004) {
        init({ message: 'A theme is already bound to this terminal.', color: 'danger' })
      }else {
       init({ message: message, color: 'danger' })
      }
      return Promise.reject(new Error(message))
    }
  },
  (error: AxiosError) => {
    // 处理 HTTP 网络错误
    let message = ''
    // HTTP 状态码
    const status = error.response?.status
    switch (status) {
      case 401:
        message = 'token 失效，请重新登录'
        // 这里可以触发退出的 action
        break
      case 403:
        message = '拒绝访问'
        break
      case 404:
        message = '请求地址错误'
        break
      case 500:
        message = 'Server Error'
        break
      case 3001:
        message = 'Incorrect username or password.'
        break  
      case 3004:  
         message = 'A theme is already bound to this terminal.'
         break
      default:
        message = 'Network connection issue'
    }

    // Message.error(message)
    init({ message: message, color: 'danger' })
    return Promise.reject(error)
  },
)
export default service
